const connect = {
  "baseUrl":
    "https://api.sivnv.ru",
  "headers": {
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
};

export default connect;
